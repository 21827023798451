import { Outlet } from 'react-router-dom';
import { COMPANIES } from 'Core/API';
import Page from 'Core/components/page';

import VMHome from '@maple-tech/visual-merchandising-web-app/HomePage';
// import Manage from '@maple-tech/visual-merchandising-web-app/Manage';

import StreamIcon from '@mui/icons-material/Stream';

export const VM_BASE_ROUTE = {
  index: '/vm',
  category: '/vm/category',
  detail: '/vm/detail',
  manage: '/vm/manage',
};

const ROUTES = {
  index: 'vm',
  category: 'category',
  detail: 'detail',
  manage: 'manage',
};

const subRoutesList = [
  {
    menuItem: false,
    requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
    path: '',
    element: (
      <Page title={'Visual Merchandising'} contentStyle={{ padding: 0 }}>
        <VMHome />
      </Page>
    ),
    index: true,
  },
];

const VMPagesRoutes = {
  menuItem: true,
  requiredRoles: ['admin', COMPANIES.LOREM, COMPANIES.WORKMAPLE],
  path: ROUTES.index,
  section: true,
  title: 'Visual Merchandising',
  icon: <StreamIcon />,
  element: <Outlet />,
  children: subRoutesList,
};

export default VMPagesRoutes;
